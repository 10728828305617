import * as React from 'react';
import classNames from 'classnames';
import { ComboBoxInputOption } from '@wix/thunderbolt-components';
import {
  IComboBoxInputImperativeActions,
  IComboBoxInputProps,
} from '../ComboBoxInput.types';
import { HAS_CUSTOM_FOCUS_CLASSNAME } from '../../../core/commons/a11y';
import { isOptionWithSelectedText } from '../utils';
import { OPTIONS_INDEX_OFFSET } from '../constants';

const noop = () => {};
const skinWithoutLabel = 'ComboBoxInputVerticalMenuSkin';

type IComboBoxInputBaseRenderProp = Pick<
  IComboBoxInputProps,
  'id' | 'onClick' | 'onDblClick' | 'onMouseEnter' | 'onMouseLeave'
> & {
  content: React.ReactNode;
  className: string;
  ariaLabel?: string;
};

type IComboBoxInputBaseProps = IComboBoxInputProps & {
  styles: { [key: string]: string };
  children(props: IComboBoxInputBaseRenderProp): React.ReactElement;
};

const generatePlaceholder = (
  placeholder: IComboBoxInputProps['placeholder'],
  className: string,
) => [
  <option value={''} disabled={true} className={className} key={'placeholder'}>
    {placeholder.text}
  </option>,
];

const DUPLICATED_VALUE = 'duplicatedValue';

const generateOptions = (
  options: Array<ComboBoxInputOption>,
  className: string,
  selectedValue: string,
  hasSelectedText: boolean,
) => {
  return options
    .map((option: ComboBoxInputOption) =>
      option.value === selectedValue && hasSelectedText ? (
        [
          <option hidden disabled value={option.value} key={option.key}>
            {option.selectedText}
          </option>,
          <option
            value={DUPLICATED_VALUE}
            original-value={option.value}
            key={`${option.key}-${option.value}`}
          >
            {option.text}
          </option>,
        ]
      ) : (
        <option value={option.value} className={className} key={option.key}>
          {option.text}
        </option>
      ),
    )
    .flat();
};

const ComboBoxInputBase: React.ForwardRefRenderFunction<
  IComboBoxInputImperativeActions,
  IComboBoxInputBaseProps
> = (props, ref) => {
  const {
    id,
    skin,
    label,
    styles,
    value,
    children,
    required,
    isDisabled,
    placeholder,
    shouldShowValidityIndication,
    validateValueAndShowIndication = noop,
    onBlur = noop,
    onFocus = noop,
    onChange = noop,
    onClick = noop,
    onDblClick = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
  } = props;

  const inputRef = React.useRef<HTMLSelectElement>(null);
  const [options, setOptions] = React.useState(props.options);

  const moveSelectedOptionToHeadOfList = React.useCallback(
    (selectedIndex: number, selectedValue: string, optionsOffset: number) => {
      if (options[0].value === selectedValue) {
        return;
      }
      const unModifiedOptions = props.options;
      const newOptions = [...unModifiedOptions];

      const selectedIndexWithOffset = selectedIndex - optionsOffset;
      const optionIndexToRemove =
        newOptions[selectedIndexWithOffset].value === selectedValue
          ? selectedIndexWithOffset
          : selectedIndexWithOffset - 1;

      const [deletedOption] = newOptions.splice(optionIndexToRemove, 1);
      newOptions.unshift(deletedOption);

      setOptions(newOptions);
    },
    [options, props.options],
  );

  React.useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  React.useEffect(() => {
    const unModifiedOptions = props.options;
    const newOptions = [...unModifiedOptions];

    if (value) {
      const matchingOptionIndex = newOptions.findIndex(
        option => option.value === value,
      );
      const matchingOption = newOptions[matchingOptionIndex];

      if (matchingOption?.selectedText) {
        moveSelectedOptionToHeadOfList(
          matchingOptionIndex,
          matchingOption.value,
          0,
        );
      }
    }
  }, [props.options, value, moveSelectedOptionToHeadOfList]);

  React.useImperativeHandle(ref, () => {
    return {
      focus: () => {
        inputRef.current?.focus();
      },
      blur: () => {
        inputRef.current?.blur();
      },
      setCustomValidity: message => {
        if (message.type === 'message') {
          inputRef.current?.setCustomValidity(message.message);
        }
      },
    };
  });

  const _onChange: React.ChangeEventHandler<HTMLSelectElement> = event => {
    const val = event.target.value;
    const selectedIndex = event.target.selectedIndex;

    if (val === DUPLICATED_VALUE) {
      const originalValue = event.target[selectedIndex].getAttribute(
        'original-value',
      ) as string;

      event.target.value = originalValue;
    }
    if (isOptionWithSelectedText(options, val)) {
      moveSelectedOptionToHeadOfList(selectedIndex, val, OPTIONS_INDEX_OFFSET);
    }

    onChange(event);
    validateValueAndShowIndication({
      value: val,
    });
  };

  const _onMouseEnter: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onMouseEnter(event);
    }
  };

  const _onMouseLeave: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onMouseLeave(event);
    }
  };

  const hideLabel = skinWithoutLabel === skin;
  const placeholderValue = placeholder && placeholder.value;
  const isLegacyPlaceholderSelected = value === placeholderValue;
  const isPlaceholderSelected =
    isLegacyPlaceholderSelected || (placeholderValue && value === '');

  const rootClassName = classNames(styles[skin], styles.root, {
    [styles.hasLabel]: !!label,
    [styles.withRequiredIndication]: required,
    [styles.withValidationIndication]: shouldShowValidityIndication,
  });

  const selectClassName = classNames(
    styles.select,
    HAS_CUSTOM_FOCUS_CLASSNAME,
    {
      [styles.extendedPlaceholderStyle]: isPlaceholderSelected,
    },
  );

  const content = (
    <>
      {hideLabel ? null : (
        <label className={styles.label} htmlFor={`${id}collection`}>
          {label}
        </label>
      )}
      <div className={styles.selectorWrapper}>
        <select
          ref={inputRef}
          className={selectClassName}
          id={`${id}collection`}
          onFocus={onFocus}
          onChange={_onChange}
          onBlur={onBlur}
          disabled={isDisabled}
          required={required}
          value={value}
        >
          {generatePlaceholder(
            placeholder,
            classNames(styles.option, styles.placeholder),
          ).concat(
            generateOptions(
              options,
              styles.option,
              value,
              isOptionWithSelectedText(options, value),
            ),
          )}
        </select>
        <div className={styles.arrow}>
          <div className={styles.svgContainer}>
            <svg
              className={styles.icon}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 9.2828 4.89817"
            >
              <title>arrow&amp;amp;v</title>
              <path d="M4.64116,4.89817a.5001.5001,0,0,1-.34277-.13574L.15727.86448A.50018.50018,0,0,1,.84282.136L4.64116,3.71165,8.44.136a.50018.50018,0,0,1,.68555.72852L4.98393,4.76243A.5001.5001,0,0,1,4.64116,4.89817Z" />
            </svg>
          </div>
        </div>
      </div>
    </>
  );

  return children({
    id,
    className: rootClassName,
    onClick,
    onDblClick,
    onMouseEnter: _onMouseEnter,
    onMouseLeave: _onMouseLeave,
    content,
    ariaLabel: label,
  });
};

export default React.forwardRef(ComboBoxInputBase);
